<template>
    <div class="h-auto w-full">
        <ner />
        <margin class="mt-2" />
        <!-- <tnr class="mt-2" /> -->
        <billing class="mt-2" />
        <revenue class="mt-2" :mobile="true"/>
        <!-- <tnrmobile/> -->
        <tnrmobile v-if="rolestnr" class="mt-2" :mobile="true"/>
        <ratemobile class="mt-2 mb-10" />
    </div>
</template>

<script>
    const Ner = () => import('@/components/Mobile/ProjectsManagement/ProjectsManagementHome/Ner')
    // const Tnrmobile = () => import('@/components/Mobile/ProjectsManagement/ProjectsManagementHome/Tnrmobile')
    const Tnrmobile = () => import('../../components/Desktop/ProjectsManagement/Tnrnew.vue')
    const Ratemobile = () => import('@/components/Mobile/ProjectsManagement/ProjectsManagementHome/Ratemobile')
    const Margin = () => import('@/components/Mobile/ProjectsManagement/ProjectsManagementHome/Margin')
    const Billing = () => import('@/components/Mobile/ProjectsManagement/ProjectsManagementHome/Billing')
    
    // const Revenue = () => import('@/components/Mobile/ProjectsManagement/ProjectsManagementHome/Revenue')
    const Revenue = () => import('@/components/Desktop/ProjectsManagement/RevenueDays')
   
    // const Rate = () => import('@/components/Desktop/ProjectsManagement/Rate')
    import { state, actions } from '@/store';

    export default {
        components: {
            Ner,
            Margin,
            Billing,
            Revenue,
            Ratemobile,
            Tnrmobile
        },
        methods:{
        },
        computed:{
            rolSelected(){
                return state.rolSelected
            },
            rolestnr(){
                if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'ROL_SL' || state.rolSelected.role_type_link == 'competencyLeader' || state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                    return true
                } else {
                    return false
                }
            }
        }
    }

</script>